<template>
  <div class="fixed-wrap">
    <div id="fixed"></div>
    <div class="nav">
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/">Home</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/trauerrednerin">Trauerrednerin</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/kontakt">Kontakt</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/impressum">Impressum</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/datenschutz">Datenschutz</v-btn>
      </div>
    </div>
    <v-row>
      <v-col sm="12" md="8" class="col"
        ><div class="data-policy">
          <v-card class="data-policy-card elevation-5">
            <v-card-text>
              <h2>Datenschutzerklärung</h2>

              <h1>1. Datenschutz auf einen Blick</h1>

              <h4>Allgemeine Hinweise</h4>

              Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
              personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene
              Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
              Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem
              Text aufgeführten Datenschutzerklärung.<br /><br />
              <h4>Datenerfassung auf unserer Website</h4>

              Wer ist verantwortlich für die Datenerfassung auf dieser Website? Die
              Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
              Kontaktdaten können Sie dem Impressum dieser Website entnehmen. Wie erfassen wir Ihre
              Daten? Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
              Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
              Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme
              erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem
              oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
              sobald Sie unsere Website betreten. Wofür nutzen wir Ihre Daten? Ein Teil der Daten
              wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
              Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden. Welche Rechte haben
              Sie bezüglich Ihrer Daten? Sie haben jederzeit das Recht unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten
              (Recht auf Unterrichtung). Sie haben außerdem ein Recht, die Berichtigung, Sperrung
              oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
              Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
              uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.<br /><br />
              <h4>Analyse-Tools und Tools von Drittanbietern</h4>

              Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden.
              Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse
              Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu
              Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch
              die Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden
              Sie in der folgenden Datenschutzerklärung. Sie können dieser Analyse widersprechen.
              Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung
              informieren.<br /><br />
              <h1>2. Allgemeine Hinweise und Pflichtinformationen</h1>

              <h4>Datenschutz</h4>

              Die Betreiber dieser Seite nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
              behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website
              benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
              sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
              Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
              erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass
              die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
              Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
              durch Dritte ist nicht möglich.<br /><br />
              <h4>Hinweis zur verantwortlichen Stelle</h4>

              Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
              Christiane Böhm, Ihmerter Str. 237, 58675 Hemer, Tel.:
              <a href="tel:015778309394">01577 - 830 93 94</a>, Mail:
              <a href="mailto:boehm.ch@t-online.de">boehm.ch@t-online.de</a>, Verantwortliche Stelle
              ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
              die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.<br /><br />
              <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>

              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
              möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu
              reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.<br /><br />
              <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>

              Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei
              der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in
              datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes,
              in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie
              deren Kontaktdaten können folgendem Link entnommen werden:
              <a
                href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a
              >.<br /><br />
              <h4>Recht auf Datenübertragbarkeit</h4>

              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung
              eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem
              gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte
              Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
              soweit es technisch machbar ist.<br /><br />
              <h4>Auskunft, Sperrung, Löschung</h4>

              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
              unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
              Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf
              Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum
              Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
              angegebenen Adresse an uns wenden.<br /><br />
              <h4>Widerspruch gegen Werbe-Mails</h4>

              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
              Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
              wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
              rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa
              durch Spam-E-Mails, vor.<br /><br />
              <h1>3. Datenerfassung auf unserer Website</h1>

              <h4>Cookies</h4>

              Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem
              Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser
              Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine
              Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Die
              meisten der von uns verwendeten Cookies sind so genannte "Session-Cookies". Sie werden
              nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem
              Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren
              Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so einstellen,
              dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
              das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der
              Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
              Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion)
              erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert.
              Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur
              technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere
              Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden
              diese in dieser Datenschutzerklärung gesondert behandelt.<br /><br />
              <h4>Server-Log-Dateien</h4>

              Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
              Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
              <ul>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
                <li>IP-Adresse</li>
              </ul>
              Die Daten werden nur für die Bereitstellung der Webinhalte verwendet und nach 24
              Stunden wieder gelöscht. Eine Zusammenführung dieser Daten mit anderen Datenquellen
              wird nicht vorgenommen. Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
              DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
              vorvertraglicher Maßnahmen gestattet.
              <br /><br />
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.nav {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}

.nav-el {
  margin: 10px 20px;
}

.btn-white {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.data-policy {
  padding: 30px;
}

.data-policy-card {
  max-width: 1200px;
  margin: 50px auto;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

#fixed {
  background-image: url(../assets/img/weg_1.jpg);
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-position: center center; */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

.v-row {
  margin: 0 !important;
}

@media screen and (max-width: 600px) {
  .nav {
    margin-top: 80px;
  }
}
</style>
