<script setup></script>

<template>
  <div class="fixed-wrap">
    <div id="fixed"></div>
    <div class="nav">
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/">Home</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/trauerrednerin">Trauerrednerin</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/kontakt">Kontakt</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/impressum">Impressum</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/datenschutz">Datenschutz</v-btn>
      </div>
    </div>
    <v-row>
      <v-col sm="12" md="8" class="col"
        ><div class="impressum">
          <v-card class="impressum-card elevation-5">
            <v-card-text>
              <h2>Impressum</h2>
              Name und Anschrift der Verantwortlichen:<br /><br />
              Christiane Böhm<br />

              Ihmerter Str. 237, 58675 Hemer<br /><br />
              Tel.: <a href="tel:015778309394">01577 - 830 93 94</a> <br />
              Mail: <a href="mailto:boehm.ch@t-online.de">boehm.ch@t-online.de</a><br /><br />
              <br />
              Urheberrecht und Nutzung: <br />Sämtliche Texte, Bilder und andere auf der
              Internetseite veröffentlichten Werke unterliegen - sofern nicht anders gekennzeichnet
              - dem Copyright der Urheberin bzw. des Seitenbetreibers. Jede Vervielfältigung,
              Verbreitung, Speicherung, Übermittlung, Sendung und Wieder- bzw. Weitergabe der
              Inhalte ist ohne schriftliche Genehmigung des Seitenbetreibers ausdrücklich
              untersagt.<br /><br />
              Fotos: eigene
              <br /><br />
              Website von Johannes Böhm
              <br /><br />
              Haftungsausschluss:<br />
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte
              externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren
              Betreiber verantwortlich.<br />
              Alle auf dieser Website dargestellten Personen widersprechen der kommerziellen
              Weitergabe der veröffentlichten Informationen.
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.nav {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}

.nav-el {
  margin: 10px 20px;
}

.btn-white {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}

.impressum {
  padding: 30px;
}

.impressum-card {
  margin-top: 50px;
  max-width: 500px;
  margin: 50px auto;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

#fixed {
  background-image: url(../assets/img/weg_1.jpg);
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-position: center center; */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

.v-row {
  margin: 0 !important;
}

@media screen and (max-width: 600px) {
  .nav {
    margin-top: 80px;
  }
}
</style>
