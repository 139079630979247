import { createRouter, createWebHashHistory } from 'vue-router'
import MainComponent from '../components/MainComponent.vue'
import GriefComponent from '../components/GriefComponent.vue'
import Contact from '../components/ContactMe.vue'
import ImpressumComponent from '../components/ImpressumComponent.vue'
import DatenschutzComponent from '../components/DatenschutzComponent.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainComponent
  },
  {
    path: '/trauerrednerin',
    name: 'trauerrednerin',
    component: GriefComponent
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: Contact
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImpressumComponent
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenschutzComponent
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
