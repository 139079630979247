<script setup></script>

<template>
  <div class="fixed-wrap">
    <div id="fixed"></div>
    <div class="nav">
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/">Home</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/trauerrednerin">Trauerrednerin</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/kontakt">Kontakt</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/impressum">Impressum</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/datenschutz">Datenschutz</v-btn>
      </div>
    </div>
    <v-row>
      <v-col sm="12" md="8" class="col"
        ><div class="welcome">
          <v-card class="welcome-card elevation-5">
            <v-card-title class="welcome-card-title">Freie Rednerin • Trauerrednerin</v-card-title>
            <v-card-text class="welcome-card-text">
              Mein Name ist Christiane Böhm. Ich wohne in Hemer-Ihmert und wurde im Jahr 1971
              geboren. Ich bin verheiratet und habe zwei erwachsene Kinder. <br /><br />
              Derzeit arbeite ich ein paar Stunden in meinem erlernten Beruf als
              Pharmazeutisch-technische Assistentin in Altena-Evingsen. Durch meinen Beruf habe ich
              viel Kontakt mit Menschen in verschiedenen Lebenssituationen. <br /><br />
              Auch während meiner Tätigkeit als Gemeindeschwester durfte ich in den letzten Jahren
              vielen Menschen zuhören, die mir Geschichten aus ihrem Leben erzählt haben. Die
              Kontakte zu den Menschen führten mich auch zu Trauergesprächen und Trauerfeiern, die
              ich mitgestalten durfte.
            </v-card-text>
          </v-card>
          <v-card class="welcome-cite-card elevation-5">
            <v-card-text class="welcome-cite-card-text">
              <i
                >Die besten Geschichten schreibt das Leben und Du bist der Autor deiner eigenen
                Reise</i
              ></v-card-text
            >
            <v-card-actions class="welcome-card-actions">Autor unbekannt</v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.nav {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}

.nav-el {
  margin: 10px 20px;
}

.btn-white {
  color: #fff;
}

.btn-grey {
  color: #fff;
  background-color: #333;
}

.welcome {
  padding: 30px;
  min-width: 300px;
}

.col {
  padding: 50px;
}

.welcome-card {
  margin: 50px auto;
  max-width: 700px;
  background-color: rgba(255, 255, 255, 0.6);
}

.welcome-card-text {
  font-size: 1.3rem;
}

.welcome-card-actions {
  font-size: 12px;
  margin-left: 20px;
}

.welcome-cite-card {
  margin: 50px auto;
  max-width: 700px;
  background-color: #cda872;
}

.contact-card {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: none !important;
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: row;
  margin-bottom: 50px;
}

.contact-text {
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.contact-img-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
}

.contact-img-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-end;
}

.tel,
.mail {
  text-decoration: none;
  color: #fff;
  margin-left: 10px;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

@media screen and (max-width: 600px) {
  .nav {
    margin-top: 80px;
  }
}

@media screen and (max-width: 960px) {
  #fixed {
    background-image: url(../assets/img/weg_2_mobile.jpg);
  }

  .welcome {
    padding: 0px;
  }

  .col {
    padding: 10px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1263px) {
  #fixed {
    background-image: url(../assets/img/weg_2_tablet.jpg);
  }
}

@media screen and (min-width: 1264px) {
  #fixed {
    background-image: url(../assets/img/weg_2_desktop.jpg);
  }
}

#fixed {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-position: center center; */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

.v-row {
  margin: 0 !important;
}
</style>
