<script setup>
import cornerLeftH from '../assets/img/corner_left_h.png'
import cornerLeftW from '../assets/img/corner_left_w.png'
import cornerRightH from '../assets/img/corner_right_h.png'
import cornerRightW from '../assets/img/corner_right_w.png'

import { useDisplay } from 'vuetify'

const { mdAndUp } = useDisplay()
</script>

<template>
  <div class="fixed-wrap">
    <div id="fixed"></div>
    <div class="nav">
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/">Home</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/trauerrednerin"
          >Trauerrednerin</v-btn
        >
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/kontakt">Kontakt</v-btn>
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/impressum"
          >Impressum</v-btn
        >
      </div>
      <div class="nav-el">
        <v-btn variant="tonal" class="btn-white" to="/datenschutz"
          >Datenschutz</v-btn
        >
      </div>
    </div>
    <v-row>
      <v-col sm="12" md="8" class="col"
        ><div class="welcome">
          <v-card class="welcome-card elevation-5">
            <v-card-text class="welcome-card-text">
              Nehmen Sie gerne mit mir Kontakt auf – auch außerhalb von Hemer,
              Menden, Altena oder Iserlohn ist dies problemlos möglich:
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-card class="contact-card">
        <v-col sm="2" lg="2" class="contact-img-left">
          <v-img
            v-if="mdAndUp"
            height="100"
            max-width="150"
            :src="cornerLeftW"
          ></v-img>
          <v-img v-else height="150" max-width="100" :src="cornerLeftH"></v-img>
        </v-col>
        <v-col sm="8" lg="8" class="contact-wrapper">
          <v-row>
            <v-col sm="12" md="4" class="contact-wrapper">
              <v-card-text class="contact-text">Christiane Böhm </v-card-text>
            </v-col>
            <v-col sm="12" md="4" class="contact-wrapper">
              <v-card-text class="contact-text"
                ><v-icon>mdi-whatsapp</v-icon>
                <a class="tel" href="tel:015778309394"
                  >01577 - 830 93 94</a
                ></v-card-text
              >
            </v-col>
            <v-col sm="12" md="4" class="contact-wrapper">
              <v-card-text class="contact-text"
                ><v-icon>mdi-email</v-icon>
                <a class="mail" href="mailto:boehm.ch@t-online.de"
                  >boehm.ch@t-online.de</a
                ></v-card-text
              >
            </v-col>
          </v-row></v-col
        >
        <v-col sm="2" lg="2" class="contact-img-right">
          <v-img
            v-if="mdAndUp"
            height="100"
            max-width="150"
            :src="cornerRightW"
          ></v-img>
          <v-img
            v-else
            height="150"
            max-width="100"
            :src="cornerRightH"
          ></v-img>
        </v-col>
      </v-card>
    </v-row>
  </div>
</template>

<style scoped>
.nav {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}

.nav-el {
  margin: 10px 20px;
}

.btn-white {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}

.btn-grey {
  color: #fff;
  background-color: #333;
}

.welcome {
  padding: 30px;
  /* margin-top: 50px; */
  min-width: 300px;
}

.col {
  padding: 50px;
}

.welcome-card {
  /* margin: 50px auto; */
  max-width: 700px;
  background-color: rgba(255, 255, 255, 0.6);
}

.welcome-card-text {
  font-size: 1.3rem;
}

.welcome-card-actions {
  font-size: 12px;
  margin-left: 20px;
}

.contact-card {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: none !important;
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: row;
  margin-bottom: 50px;
}

@media screen and (max-width: 600px) {
  .nav {
    margin-top: 80px;
  }
}

@media screen and (max-width: 960px) {
  .col {
    padding: 10px;
  }
}

@media screen and (min-width: 960px) {
  .contact-card {
    margin-top: 150px;
  }

  .welcome {
    padding: 0px;
  }
}

.contact-text {
  font-size: 1.3rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
  display: flex;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: center;
}

.contact-wrapper {
  display: flex;
  align-content: center;
}

.contact-img-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
}

.contact-img-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: flex-end;
}

.tel,
.mail {
  text-decoration: none;
  color: #fff;
  margin-left: 10px;
}

.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

#fixed {
  background-image: url(../assets/img/weg_1.jpg);
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-position: center center; */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform;
}

.v-row {
  margin: 0 !important;
}
</style>
